import { Box, styled } from '@mui/material'

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`
export const SpaceBetweenBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CenterBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const SpaceBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`
export const FlexEndBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
