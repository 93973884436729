import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { AppState } from '../index'
import { ApplicationModal, setOpenModal } from './reducer'

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal)
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}

export function usePoolValues() {
  return useAppSelector((state: AppState) => state.application.poolValues)
}

export function useCollections() {
  return useAppSelector((state: AppState) => state.application.collections)
}

export function useDepositedCollection() {
  return useAppSelector((state: AppState) => state.application.depositedCollection)
}

export function useLoading() {
  return useAppSelector((state: AppState) => state.application.loading)
}

export function useShowChangeNetWork() {
  return useAppSelector((state: AppState) => state.application.showChangeNetWork)
}
